var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"white shadow1 rounded",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return _vm.getCashLedger($event)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Date From")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}},'v-text-field',attrs,false),on),[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('v-date-picker',{model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"pl-3",staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Date To")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}},'v-text-field',attrs,false),on),[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('v-date-picker',{model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}})],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"type":"submit","dark":"","color":"text_bg_fave","loading":_vm.loading}},[_vm._v("Search")])],1)],1),_c('v-divider')],1)],1)],1),_c('v-row',{staticClass:"py-2"},[(_vm.$store.getters['cashTransaction/ledgers'].length)?[_c('vue-excel-xlsx',{staticClass:"ml-2 mb-2",attrs:{"data":_vm.$store.getters['cashTransaction/ledgers'],"columns":_vm.columns,"file-name":("Cash-Ledger-" + (new Date().getDate()) + "-" + (new Date().toLocaleString(
                'default',
                { month: 'long' }
                )) + "-" + (new Date().getFullYear())),"file-type":'xlsx',"sheet-name":'sheetname'}},[_c('v-btn',[_vm._v(" Download Excel ")])],1)]:_vm._e(),_c('v-col',{attrs:{"cols":"12","id":"printContent"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Serial")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Cash In")]),_c('th',[_vm._v("Cash Out")]),_c('th',[_vm._v("Balance")])])]),_c('tbody',[_c('tr',[_c('td'),_c('td'),_c('td',{staticClass:"text-left"},[_c('strong',[_vm._v("Opening Balance")])]),_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters['cashTransaction/openingBalance']))])])]),_vm._l((_vm.$store.getters['cashTransaction/ledgers']),function(ledger,ind){return _c('tr',{key:ind},[_c('td',[_vm._v(_vm._s(ind + 1))]),_c('td',[_vm._v(_vm._s(ledger.date))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(ledger.description))]),_c('td',[_vm._v(_vm._s(ledger.cash_in))]),_c('td',[_vm._v(_vm._s(ledger.cash_out))]),_c('td',[_vm._v(_vm._s(ledger.balance))])])})],2)]},proxy:true}])})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }